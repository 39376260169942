import React from 'react';
import './Guide.css'
function Page2() {
  return (
    <div>
      <h1>Как работают нфт</h1>
     

<p>Давайте начнем с обзора того, как работают NFT или токены невзаимозаменяемости.

<p>Обычно у любого NFT есть три важных компонента: </p>
<ul>

<li>Смарт-контракт - как NFT хранится на блокчейне?</li>
<li>Метаданные - ссылки на мультимедийные ресурсы, связанные с этим NFT, а также любые атрибуты/характеристики.</li>
<li>Мультимедийные ресурсы - файлы мультимедиа-ресурсов (изображения, видео и т.д.), на которые ссылается метаданные NFT.</li>

</ul>
Давайте рассмотрим каждый из них более подробно.</p>

<button className='next'>СмартКонтракты - &gt;</button>

    </div>
  );
}

export default Page2;