import React from "react";
import './PageMain.css'

function PageMain() {
  return (
    <section className="section-top">
      
        <div className="main_text">
         
            <p className="info">
              Contract Maker представляет собой бесплатный инструмент, который
              позволяет конфигурировать, компилировать, развертывать и проверять
              свои NFT смарт-контракты.
            </p>

            <p className="info">
              Наша цель - помочь вам создать, задеплоить и продать вашу
              NFT-коллекцию без лишних трудозатрат и необходимости иметь
              предварительный опыт в программировании.
            </p>

            <p className="info">
              Вы сами устанавливаете цены и правила продажи на ваши коллекции, а
              полученную прибыль получаете непосредственно на свой кошелек.
            </p>

            <p className="info">
              Присоединяйтесь к нашей платформе и создавайте уникальные
              NFT-коллекции уже сегодня!
            </p>
          </div>
      
      
    </section>
  );
}

export default PageMain;
